/*
    Yourgi Service routes are using optional parameters because during development we 
    ran into issues with the route requiring the ID to be present on page load. Using the
    optional parameter allows us to load the page without the ID and then add it later. This 
    is a known issue with Vue Router, and has been reported. This solution is 
    a workaround until the issue is resolved.

    https://github.com/vuejs/vue-router/issues/2110
    */

const routes = [
  {
    path: '/yourgi-services',
    component: () => import('./pages/YourgiServicesBase.vue'),
  },
  {
    name: 'YourgiServicesBase',
    path: '/yourgi-services/:id?',
    component: () => import('./pages/YourgiServicesBase.vue'),
    meta: {
      pageTitle: 'Yourgi Services',
      requiresAuth: true,
      resourceName: 'CNTR',
    },
    children: [
      {
        name: 'YourgiServices',
        path: 'services',
        component: () => import('./pages/YourgiServices.vue'),
      },
      {
        name: 'AddAppointmentType',
        path: 'addAppointmentType',
        component: () => import('./pages/AddAppointmentType.vue'),
      },
      {
        name: 'YourgiServiceAddons',
        path: 'addons',
        component: () => import('./pages/Addon.vue'),
      },
      {
        name: 'YourgiService',
        path: 'services/:centerAppointmentTypeId',
        component: () => import('./pages/YourgiService.vue'),
      },
      {
        name: 'YourgiAppointmentCategoryEdit',
        path: 'edit-appointment/:centerAppointmentTypeId',
        component: () => import('./pages/YourgiAppointmentServicesEdit.vue'),
      },
      {
        name: 'YourgiServicesEdit',
        path: 'appointment/:centerAppointmentTypeId/service/:serviceId',
        component: () => import('./pages/YourgiServicesEdit.vue'),
      },
      {
        name: 'YourgiServiceLodgingsEdit',
        path: 'appointment/:centerAppointmentTypeId/service/:serviceId/lodging',
        component: () => import('./pages/YourgiServiceLodgingsEdit.vue'),
      },
    ],
  },
]

export default routes
